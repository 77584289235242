import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { StaticImage } from 'gatsby-plugin-image'
import ZawodyInfo from '../components/ZawodyInfo'
import Lista_startowa_roztocze from "../assets/Lista_startowaURR24.pdf"
import RaceBookURR2024 from "../assets/RaceBookURR2024.pdf"




const Title = styled.h2`
  text-align: center;
  font-size: 1.8em;
  font-weight: 100;
  padding: 20px;
  span{
    font-weight: 500;
    color: #34D277;
  }

  ${({theme})=> theme.media.tablet}{
    font-size: 2em;
    max-width: 80vw;
    margin: auto;
  }
`

const Story = styled.h3`
  text-align: center;
  font-size: 1.2em;
  font-weight: 100;
  padding: 20px;
  span{
    font-weight: 500;
    color: #34D277;
  }
   ${({theme})=> theme.media.tablet}{
    margin: auto;
    max-width: 80vw;
  }
`

const Win = styled.section`
  padding: 20px;


  h4{
    text-decoration: underline;
    color: #53000D;
    padding-top: 10px;
  }
  h4,p {
    text-align: center;
  }

  ul{
    font-weight: 300;
    padding-top: 5px;
  }

  h5{
    font-size: 1.2em;
    font-weight: 100;
    padding: 20px;
    text-align: center;
  }

  a{
    color: black;
    font-weight: 500;
    /* text-decoration: underline; */
  }

  .img{
    border: 10px solid whitesmoke;
    box-shadow: gray;
    margin: 10px 0px;
  }

  ${({theme})=> theme.media.tablet}{
    max-width: 60vw;
    margin: auto;
  }
  .star{
    text-align: left;
    font-size: 0.8em;
    ${({theme})=> theme.media.tablet}{
      font-size: 0.9em;
    }
  }
`


const TitleS = styled.h1`
  text-align: center;
  margin: auto;
  padding-top: 30px;
  justify-content: center;
`
const ListaLink = styled.a`
  color: black;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 80px;
  

  :hover{
    color: #34D277;
  }
`



const Zawody = () => {
  return (
    <Layout>
        <Seo title="Zawody rowerowe zasady"/>
        {/* <TitleS>
          <ListaLink href={Lista_startowa_roztocze} title="Lista Startowa URR 2024">Lista Startowa URR 2024</ListaLink>
        </TitleS>
        <TitleS>
          
          <ListaLink href={RaceBookURR2024} title="RaceBook URR 2024">RaceBook</ListaLink>
          <br/>
        </TitleS>
        */}
      
        <ZawodyInfo/>
        <Title>Nagrody w zawodach gravelowych <br/><span>Ultra Race Roztocze 2024</span></Title>
        <Story>Wiemy, że Gravelowe zawody to przede wszystkim spotkania z ludźmi, dużo pozytywnych emocji i walka samym z sobą. Ale każda przygoda rowerowa smakuje lepiej jak można rywalizować z innymi na trasie. Dlatego też przygotowaliśmy dla Was nagrody. <span>Pula nagród będzie się powiększać.</span></Story>

        <Win>
        <Story>Nagrody przewidziane dla dystansu GIGANTE, CLASSICO i PICCOLO</Story>
    <p>Wyboru - czy jedziesz indywidualnie czy drużynowo - dokonasz przy zapisie na godziny startów. </p>
        <h4>KATEGORIA INDYWIDUALNA - PICCOLO, CLASSICO i GIGANTE</h4>
        <p>I-III miejsce</p>
          <ul>Open Kobiety </ul>
            <li>pamiątkowe statuetki</li>
            <li>nagrody rzeczowe</li>
          <ul>Open Mężczyźni</ul>
            <li>pamiątkowe statuetki</li>
            <li>nagrody rzeczowe</li>
<br/>
       
        <h4>KATEGORIA DRUŻYNOWA - CLASSICO i GIGANTE *</h4>
        <p>Drużyna musi liczyć 3-5 uczestników. Pomiar czasu liczony jest najlepszym 3 zawodniczkom/zawodnikom z drużyny. Minimum 3 os. z dużyny musi dojechać do mety.</p>
        <ul>Najlepsza Drużyna Kobieca</ul>
          <li>pobyt (3 noclegi) w <a href='https://morehomecycling.pl' target={"_blank"} rel="noreferrer">Dom dla kolarzy - MoreHomeCycling.pl</a> - dla maks. 7 osób</li>
        <ul>Najlepsza Drużyna Open (mieszana)</ul>
          <li>pobyt (3 noclegi) w <a href='https://morehomecycling.pl' target={"_blank"} rel="noreferrer">Dom dla kolarzy - MoreHomeCycling.pl</a> - dla maks. 7 osób</li>
        <a href='https://morehomecycling.pl' target={"_blank"} rel="noreferrer">
          <StaticImage
              src="../assets/images/moreHome.jpeg"
              alt="more home dom dla kolarzy"
              placeholder="blurred"
              layout='constrained'
            //   width={600}
              height={600}     
              transformOptions={{ fit: "cover", cropFocus: "attention" }}
              className='img'
            ></StaticImage></a>
        <p className='star'>*Musi wystartować minimum 3 drużyny w danej kategorii.</p>
        <br></br>
        {/* <h4>Konkursy </h4>
        <p>Dodatkowo przewidyjemy nagrodyza udział w konkursach rozgrywanych w trakcie zawodów.</p>
            <br></br>     */}
        
              
        </Win>
    </Layout>
  )
}

export default Zawody;
